<template>
    <div>
        <Header></Header>
        <div class="container mx-auto py-4">
            <iframe :src="detail.link" title="Tripwe" class="w-full h-screen"></iframe>
        </div>
        <Footer></Footer>
    </div>
</template>

<script>
    import Header from '@/components/Header';
    import Breadcrumb from '@/components/layout/Breadcrumb.vue';
    import Footer from '@/components/Footer';

    export default {
        name: 'PageFrame',
        data() {
            return {
                detail: {},
            };
        },
        components: {
            Header,
            Breadcrumb,
            Footer
        },
        async created() {
            this.detail = this.$route.params.formData;
        },
    };
</script>
